@import "./assets/scss/variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $SFPROTEXT !important;
  background-color: $BG_WHITE;
  color: $TEXT_BLACK;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Bold.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Heavy.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Light.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Medium.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/SFProText-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Regular.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Semibold.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'dundee-recovery';
  src:  url('./assets/fonts/dundee-recovery.eot?iicar6');
  src:  url('./assets/fonts/dundee-recovery.eot?iicar6#iefix') format('embedded-opentype'),
    url('./assets/fonts/dundee-recovery.ttf?iicar6') format('truetype'),
    url('./assets/fonts/dundee-recovery.woff?iicar6') format('woff'),
    url('./assets/fonts/dundee-recovery.svg?iicar6#dundee-recovery') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dundee-recovery' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-business:before {
  content: "\e900";
}
.icon-calender:before {
  content: "\e901";
}
.icon-edit:before {
  content: "\e902";
}
.icon-event:before {
  content: "\e903";
}
.icon-pin:before {
  content: "\e904";
}
.icon-warning:before {
  content: "\e905";
}
.icon-watch:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-eye-hide:before {
  content: "\e90b";
}
.icon-email:before {
  content: "\e90c";
}
.icon-arrow-down:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e90f";
}
.icon-eye-show:before {
  content: "\e910";
}
.icon-link:before {
  content: "\e911";
}
.icon-linkedin:before {
  content: "\e912";
}
.icon-info:before {
  content: "\e913";
}
.icon-check-outline:before {
  content: "\e914";
}
.icon-delete:before {
  content: "\e915";
}
.icon-pencil:before {
  content: "\e916";
}
.icon-arrow-right:before {
  content: "\e917";
}
.icon-close-cricle:before {
  content: "\e918";
}
.icon-phone:before {
  content: "\e919";
}
.icon-success:before {
  content: "\e91a";
}
.icon-sorting:before {
  content: "\e91b";
}
.icon-calender-outline-fill:before {
  content: "\e91c";
}
.icon-pin-outline:before {
  content: "\e91d";
}
.icon-setting:before {
  content: "\e91e";
}
.icon-menu:before {
  content: "\e91f";
}
.icon-back:before {
  content: "\e920";
}






.modal-backdrop.show {
  opacity: .2;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px transparent inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px transparent inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
} 
.countryPhoneGroup{
  float: left;
  width: 100%;
  position: relative;
  z-index: 111;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  &::before{
    width: 100%;
    height: 47px;
    top: -1px;
    position:absolute;
    left: 1px;
    content: "";
    border-radius: 8px;
    border: 1px solid rgba(50, 14, 59, 0.2);
  }
  // overflow: hidden;
  .icon-phone {
    position: absolute;
    font-size: 20px;
    cursor: pointer;
    color: #A99AAD;
    padding: 0 10px;
    top: 13px;
    left: 0px;
  }
  .react-tel-input {
    position: absolute;
    left: 40px;
    top: 0px;
    width: 50px;
    outline: none;
    z-index: 1;
    .country-list{
      left: -38px;
      .search{
        padding: 10px 10px 6px 10px;
        .search-box{
          margin-left: 0px;
          width: 100%;
          font-family: $SFPROTEXT;
          font-size: 14px;
          padding-top: 8px;
          padding-bottom: 6px;
        }
      }
      .country{
        font-family: $SFPROTEXT;
        .country-name{
          color: #320E3B;
        }
      }
    }
    .special-label {
      display: none;
    }
  
    .country-list {
      .country {
        text-align: left;
      }
    }

    .selected-flag {
      background: transparent !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      padding: 0px;
      .flag {
        opacity: 0;
      }
    }

    .flag-dropdown {
      border: none;
      background: transparent !important;
    }

    .form-control {
      width: 50px;
      height: 44px;
      padding: 0px !important ;
      text-align: left;
      border: none;
      background: transparent;
      outline: none;
      box-shadow: none;
      font-size: 14px;
      font-family: $SFPROTEXT;
      &::placeholder {
        color: $PLACEHOLDER;
        opacity: 1;
      }
  
      &:-ms-input-placeholder {
        color: $PLACEHOLDER;
      }
  
      &::-ms-input-placeholder {
        color: $PLACEHOLDER;
      }
    }
  }

  .TextFieldNoRequired{
    padding-left: 90px;
    margin-bottom: 0px;
    .form-control {
      padding-left: 0px !important;
      border: none;
      background-color: #fff;
      border-radius: 0px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .errorMsg{
      position: relative;
      width: calc(100% + 90px);
      left: -90px;
    }
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #320e3b !important;
  color: white;
  font-weight: bold;
}