@import "../../../assets/scss/variables.scss";

.btn {
  &.custombtn {
    background: $BG_SECONDARY_ONE;
    width: 100% ;
    font-family: $SFPROTEXT;
    font-size: $FONT_16 ;
    font-weight: 600;
    border-radius: 5px ;
    padding: 11px 24px;
    box-shadow: none;
    min-height: 48px;
    border:1px solid transparent !important;
    &.outline_btn{
      background-color: $BG_WHITE;
      border: 1px solid $TEXT_SECONDARY_ONE !important;
      color: $TEXT_SECONDARY_ONE;
      &:not(:disabled) {
        box-shadow: none;
        background: $BG_WHITE;
      }
      &:not(:disabled):not(.disabled):active {
        background: $BG_WHITE;
        color: $TEXT_SECONDARY_ONE;
        border: 1px solid $TEXT_SECONDARY_ONE;
      }
  
      &:not(.disabled):focus {
        box-shadow: none !important;
        background: $BG_WHITE;
        color: $TEXT_SECONDARY_ONE;
      }
    }
    &:disabled {
      opacity: 1;
      cursor: no-drop;
      background: $BG_DISABLE;
      span{
        color: $TEXT_BLUE_DARK;
        opacity: 0.5;
      }
      &:focus {
      }
    }

    &:not(:disabled) {
      box-shadow: none;
      background: $BG_SECONDARY_ONE;
    }
    // &:not(.disabled):active {
    //   box-shadow: none;
    //   background: $BG_SECONDARY_ONE;
    // }

    &:not(:disabled):not(.disabled):active {
      background: $BG_SECONDARY_ONE;
    }

    &:not(.disabled):focus {
      box-shadow: none !important;
      background: $BG_SECONDARY_ONE;
    }

    &.btn-loading {
      opacity: 1 !important;
      cursor: default;
    }
  }
}
