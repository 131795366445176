@import "./assets/scss/variables.scss";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pageMain {
  float: left;
  position: relative;
  max-width: 1445px;
  width: 100%;
  padding-left: 0px;

  .page {
    float: left;
    position: relative;
    width: 100%;
    background-color: $BG_Body;
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;
    // box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08) inset;
    // -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08) inset;
    // -moz-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.08) inset;
  }
}

.suggest-port {
  .modal-dialog {
    width: 920px;
    max-width: 100%;
  }
}

.suggest-full {
  width: 100%;
  float: left;

  form {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .suggest-part {
      width: 48%;
      float: left;

      .textField {
        margin-bottom: 20px;
      }

      h2 {
        margin: 0 0 25px 0;
        text-align: center;
        font-family: $SFPROTEXT;
        font-size: $FONT_24;
        color: $TEXT_RED;
      }

      .uploadedImg {
        width: 100%;
        cursor: pointer;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .uploadedImgText {
          text-align: center;

          i {
            color: $TEXT_BLACK;
            font-size: $FONT_22;
          }

          p {
            margin: 0;
            font-family: $SFPROTEXT;
            font-size: $FONT_12;
            color: $TEXT_BLACK;
            span {
              font-family: $SFPROTEXT;
              font-size: $FONT_12;
              color: $TEXT_RED;
            }
          }
          .upload_port_harbour {
            margin: 5px 0 0 0;
            font-family: $SFPROTEXT;
            font-size: $FONT_12;
            color: $TEXT_GREEN;
          }
        }

        img {
          width: 85px;
          height: 85px;
          margin: 0;
          padding: 0;
          object-fit: contain;
        }

        .imageDetails {
          padding-left: 10px;

          .fileName {
            font-family: $SFPROTEXT;
            font-size: $FONT_12;
            color: $TEXT_BLACK;
            margin: 0;
            text-align: left;
            max-width: 200px;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .fileSize {
            font-family: $SFPROTEXT;
            font-size: $FONT_12;
            margin: 0;
            text-align: left;
            max-width: 200px;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .changeText {
            font-family: $SFPROTEXT;
            font-size: $FONT_12;
            margin: 0;
            text-align: left;
          }
        }
      }

      .dropzoneCustom {
        display: block;
        width: 100%;
        border-radius: 10px;
        background-color: $BG_RED;
        border: 2px dashed $BG_GREEN;
        padding: 8px 0;

        .txtDropzone {
          position: relative;
          width: 100%;
          &:focus {
            outline: none;
          }
          .imgBoxEmpty {
            &:focus {
              outline: none;
            }
          }
        }
      }

      .footer-button {
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .custombtn {
          margin: 0 10px;
          padding-left: 10px !important;
          padding-right: 10px !important;
          min-width: inherit;
          width: auto !important;

          i {
            margin-right: 5px;
            font-size: 20px !important;
            top: 0;
          }
        }
        .cancel {
          background-color: $BG_RED;
          color: $TEXT_GREEN !important;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            margin-right: 5px;
            font-size: 20px !important;
            top: 0;
          }
        }
      }

  
    }
  }
}

.category_modal{
    width: 317px;
    .modal-content{
      width: 92%;
      margin: 0 auto;
      .modal-header{
        background-color: $BG_SECONDARY_ONE;
        align-items: center;
        .modal-title{
          font-size: $FONT_16;
          line-height: 20px;
          font-family: $SFPROTEXT;
          font-weight: 600;
          color: $TEXT_WHITE;
        }
        .icon-close{
          outline: none;
          border: none;
          background-color: transparent;
          color: $TEXT_WHITE;
          font-size: $FONT_14;
          padding: 0px;
        }
      }
      .modal-body{
        width: 100%;
        float: left;
        padding: 24px 24px;
        .labelTitle {
          float: left;
          font-size: 16px;
          font-family: $SFPROTEXT;
          font-weight: 400;
          line-height: 20px;
          color: $TEXT_BLUE_DARK;
          padding-bottom: 8px;
        }
        .textField{
          .form-control{
            padding-right: 16px;
          }
          .iconSection{
            display: none;
          }
        } 
        .modal-footer{
          width: 100%;
          float: left;
          padding: 0px;
          margin: 24px 0 0 0;
          justify-content: space-between;
          padding-top: 24px;
          .custombtn{
            width: 117px;
            margin: 0px;
          }
        }
      }
    } 
}

.calenderContainer {
  width: 48%;

  .custom-date-input {
    background: $BG_WHITE;
    font-family: $SFPROTEXT;
    color: $TEXT_GREEN;
    font-size: $FONT_14;
    padding: 12.5px 14px;
    padding-right: 40px;
    height: auto;
    width: 100%;
    border-radius: 8px;
    border-width: 1px;
    border-color: $BG_GREEN;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: left;
    &::after {
      position: absolute;
      top: 8px;
      right: 16px;
      background-color: transparent;
      color: $BG_RED;
      height: 18px;
      width: 18px;
      padding: 0;
      font-size: $FONT_20;
      content: "\e91f";
      font-family: "zeadog" !important;
    }
  }

  .custom-date-placeholder {
    color: $BG_RED;
  }

  .react-datepicker__close-icon {
    padding: 0;
    margin-right: 12px;

    &::after {
      background-color: transparent;
      color: $BG_RED;
      height: 18px;
      width: 18px;
      padding: 0;
      font-size: $FONT_16;
      content: "\e91f";
      font-family: "zeadog" !important;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
    }
  }
}
.edit_privacy_policy{
  .modal-dialog {
    width: 770px;
    .modal-content {
      background-color: $BG_WHITE;
      padding: 40px 80px 80px;
      .icon-warning{
        display: none;
      }
      .modal-header{
        text-align: left;
        p {
          margin: 0;
          font-family: $SFPROTEXT;
          font-size: 20px;
          line-height: 24px;
          color:$TEXT_SECONDARY_ONE;
          font-weight: 600;
          padding: 0 0 16px;
        }
      }
      .modal-body{
        .date_txt{
          position: absolute;
          top: -45px;
          right: 0;
          font-size: 14px;
          line-height: 16px;
          color: #BBBBBB;
        }
        
        .textareaBorder {
          width: 100%;
          float: left;
          border: 1px solid $INPUT_BORDER;
          -webkit-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
          -moz-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
          -ms-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
          -o-box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
          box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
          .DraftEditor-editorContainer, 
          .DraftEditor-root, 
          .public-DraftEditor-content{
            height: 100%;
          }
          .public-DraftStyleDefault-ol, 
          .public-DraftStyleDefault-ul{
            width: 100%;
            float: left;
          }
          .public-DraftStyleDefault-orderedListItem{
            display: flex;
            width: 100%;
          }
          .rdw-link-modal{
            height: 255px;
          }
          .DraftEditor-root{
            width: 100%;
            float: left;
            .public-DraftStyleDefault-block{
              font-size: $FONT_14;
              font-weight: 400;
              line-height: 20px;
              font-family: $SFPROTEXT;
              width: 100%;
              float: left;
              color: $TEXT_SECONDARY_TWO;
              margin: 0px;
            }
            .public-DraftEditorPlaceholder-inner{
              font-size: $FONT_14;
              font-weight: 400;
              line-height: 16px;
              font-family: $SFPROTEXT;
              width: 100%;
              float: left;
              color: $PLACEHOLDER;
            }
          }

          .rdw-editor-toolbar{
            border-top: none;
            border-left: none;
            border-right: none;
            padding: 10px 16px;
            margin-bottom: 0px;
            border-bottom: 1px solid $INPUT_BORDER;
            .rdw-inline-wrapper{
              margin: 0px;
            }
            .rdw-list-wrapper{
              margin: 0px;
            }
            .rdw-link-wrapper{
              margin: 0px;
            }
          }
          .wrapperClassName{
            .resizeClass{
              padding: 16px 16px;
              height: 450px;
              overflow: auto;
              resize: none !important;
            }
          }
        }
        p{
          padding-bottom: 10px;
        }
      }
      .custombtnfield {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-top: 24px;
        .custombtn{
          width: 164px;
          min-height: 36px;
          margin-left: 16px;
          font-weight: 600;
          padding: 0px;
          border-radius: 8px;
        }
      }
    }
  }
}
.confirm-logout{
  .modal-dialog {
    .modal-content {
      .modal-body{
        p{
          padding-bottom: 10px;
        }
      }
      .modal-footer {
        .cancle {
          background-color: $BG_WHITE;
          color: $TEXT_GREEN !important;
          border: 1px solid $BG_RED !important;
        }
        .active {
          background-color: $BG_GREEN;
          color: $TEXT_WHITE !important;
          border: 1px solid $BG_GREEN !important;
        }
      }
    }
  }
}
.confirmModal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        &::before {
          background-color: $BG_RED;
        }
      }
      .modal-footer {
        .cancle {
          background-color: $BG_WHITE;
          color: $TEXT_GREEN !important;
          border: 1px solid $BG_RED !important;
        }
        .active {
          background-color: $BG_GREEN;
          color: $TEXT_WHITE !important;
          border: 1px solid $BG_GREEN !important;
        }
      }
    }
  }
}

.disabledModal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        &::before {
          background-color: $BG_GREEN;
        }
      }
    }
  }
}
.no_categroy{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no_categroy_logo{
    padding-bottom: 60px;
  }
  .no_categroy_txt{
    font-family: $SFPROTEXT;
    font-weight: 400;
    font-size: $FONT_16;
    line-height: 20px;
    color: #808080;
  }
}
.no_categroy_row{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no_categroy_logo{
    padding-bottom: 22px;
  }
  .no_categroy_txt{
    font-family: $SFPROTEXT;
    font-weight: 400;
    font-size: $FONT_16;
    line-height: 20px;
    color: #808080;
    text-align: center;
    padding: 0 20px;
  }
}
.no_business_row{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no_categroy_logo{
    padding-top: 85px;
    padding-bottom: 22px;
  }
  .no_categroy_txt{
    font-family: $SFPROTEXT;
    font-weight: 400;
    font-size: $FONT_16;
    line-height: 20px;
    color: #808080;
    text-align: center;
    padding: 0 20px;
  }
}
.add_business_modal{
  .modal-dialog{
    max-width: 770px;
    width: 96%;
  }
}

.overLayMenu {
  background: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
.date-filter-main{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 16px;
  &.disabled{
    pointer-events: none;
  }
  &:last-child{
    padding-bottom: 0px;
  }
  .datetime_start_title{
    width: 100%;
    font-size: $FONT_16;
    line-height: 20px;
    color: $TEXT_BLUE_DARK;
    padding-bottom: 8px;
    &::after{
      content: "*";
      position: relative;
      color:$TEXT_RED;
      font-size: $FONT_16;
    }
  }
  .datetime_title{
    width: 100%;
    font-size: $FONT_16;
    line-height: 20px;
    color: $TEXT_BLUE_DARK;
    padding-bottom: 8px;
  }
 
  .calenderCustomContainer{
    width: 49%;
    .custom-date-input{
      width: 100%;
      background-color: transparent;
      border: 1px solid $INPUT_BORDER;
      text-align: left;
      border-radius: 8px;
      padding: 12px 16px;
      font-size: $FONT_14;
      color: $TEXT_SECONDARY_TWO;
      &::after {
        content: "\e901";
        font-family: 'dundee-recovery' !important;
        font-size: 20px;
        color: #838AA3;
        position: absolute;
        right: 16px;
        top: 8px;
      }
      .custom-date-placeholder{
        color: $PLACEHOLDER;
      }
    }
  }
  .calenderContainer{
    width: 49%;
    .custom-time-input{
      width: 100%;
      background-color: transparent;
      border: 1px solid $INPUT_BORDER;
      text-align: left;
      border-radius: 8px;
      padding: 12px 16px;
      font-size: $FONT_14;
      color: $TEXT_SECONDARY_TWO;
      &::after {
          content: "\e906";
          font-family: 'dundee-recovery' !important;
          font-size: 20px;
          color: #838AA3;
          position: absolute;
          right: 16px;
          top: 8px;
      }
    
    
      .custom-date-placeholder{
        color: $PLACEHOLDER;
      }
    }
  }
}

.searchMainDropdown {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 16px;
  .icon-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    background: $BG_WHITE;
    border: none;
    color: $TEXT_SECONDARY_TWO;
    font-size: $FONT_14;
    padding: 2px;
  }
  .select-search {
    width: 100%;
    height:48px;
    .select-search__value {
      input:disabled {
        color: #8b959e;
        &::-webkit-input-placeholder {
          color: #8b959e;
        }
        &::-moz-placeholder {
          color: #8b959e;
        }

        &:-ms-input-placeholder {
          color: #8b959e;
        }

        &:-moz-placeholder {
          color: #8b959e;
        }
      }

      .select-search__input {
        height: 48px;
        background: #ffffff;
        font-family: $SFPROTEXT;
        font-weight: 400;
        color: $TEXT_SECONDARY_TWO;
        font-size: $FONT_14;
        padding: 14px 12px;
        padding-right: 55px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $INPUT_BORDER;
        outline: none;
        -webkit-appearance: none;
        box-shadow: none;
        &:hover {
          border: 1px solid $INPUT_BORDER;
        }

        &::-webkit-input-placeholder {
          color: $PLACEHOLDER;
        }

        &::-moz-placeholder {
          color: $PLACEHOLDER;
        }

        &:-ms-input-placeholder {
          color: $PLACEHOLDER;
        }

        &:-moz-placeholder {
          color: $PLACEHOLDER;
        }
      }

      &::after {
        border: none !important;
        content: "\e908";
        font-family: 'dundee-recovery' !important;
        transform: none !important;
        color: $PLACEHOLDER;
        top: 12px;
        right: 16px;
        width: auto;
        height: auto;
      }
    }
    .select-search__select {
      z-index: 99 !important;
      top: 50px !important;
      border: 1px solid $INPUT_BORDER;
      .select-search__options {
        margin: 0 !important;
        padding: 0 !important;
        max-height: 200px !important;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $BG_SECONDARY_ONE !important;
        .select-search__row {
          border: none;
          .select-search__option {
            font-family: $SFPROTEXT;
            color: $TEXT_SECONDARY_TWO;
            font-size: $FONT_14;
            padding: 12px 14px;
            height: auto;
            &:hover {
              background: $BG_SECONDARY_ONE;
              color: $TEXT_WHITE;
            }
          }
          .is-selected {
            background: $BG_SECONDARY_ONE;
            color: $TEXT_WHITE;
            &:hover {
              background: $BG_SECONDARY_ONE;
              color: $TEXT_WHITE;
            }
          }
        }
      }
    }
  }
}
.phone_view{
  width: 100%;
  float: left;
  margin-bottom: 16px;
  .errorMsg{
    width: 100%;
    float: left;
    font-size: 12px;
    text-align: left;
    color: #ffffff;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    &.show{
      padding: 8px 0 0;
      color: #FF4539;
      max-height: 130px;
      transition: max-height 0.3s ease-in;
    }
  }
}
.phone_input_row {
  position: relative;
  width: 100%;
  float: left;
  z-index: 99;
  border: 1px solid $INPUT_BORDER;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &.error_show{
    border: 1px solid #FF4539;
  }
  .icon-phone {
    position: relative;
    font-size: 20px;
    cursor: pointer;
    color: #A99AAD;
    padding: 0 10px;
  }
  .react-tel-input {
    width: 100%;
    outline: none;
    .special-label {
      display: none;
    }
    .country-list {
      z-index: 11;
      left: -40px;
      border-radius: 5px;
      .search{
        padding-right: 10px;
        .search-box{
          width: 100%;
          padding: 8px 8px 8px;
          font-family: $SFPROTEXT;
          font-size: 14px;
          border-color: $INPUT_BORDER;
        }
      }
      .flag{
        display: none;
      }
      .country-name{
        font-family: $SFPROTEXT;
        color: $TEXT_SECONDARY_TWO;
        font-size: $FONT_14;
      }
      .dial-code{
        font-family: $SFPROTEXT;
        color: $TEXT_SECONDARY_TWO !important;
        font-size: $FONT_14;
      }
      .country {
        text-align: left;
      }
    }

    .selected-flag {
      background: transparent !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30px;
      padding: 0px;

      .flag {
        opacity: 1;
        position: relative;
        top: 0;
        background-image: none;
        .arrow {
          display: none;
          position: relative;
          top: 0;
          margin-top: 0px;
          left: 0px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #555;
      }
      }
    }

    .flag-dropdown {
      border: none;
      background: transparent !important;
      // &::after{
      //   content: "";
      //   width: 1px;
      //   height: 20px;
      //   background-color: red;
      // }
    }

    .form-control {
      width: 100%;
      height: 47px;
      padding: 0 0px !important;
      border: none;
      text-align: left;
      background: transparent;
      outline: none;
      box-shadow: none;
      font-size: 14px;
      font-family: $SFPROTEXT;
      &::placeholder {
        color: $PLACEHOLDER;
        opacity: 1;
      }
  
      &:-ms-input-placeholder {
        color: $PLACEHOLDER;
      }
  
      &::-ms-input-placeholder {
        color: $PLACEHOLDER;
      }
    }
  }

  .TextFieldNoRequired {
    width: calc(100% - 100px);
    margin-left: 100px;
    .form-control {
      padding-left: 100px;
      padding-top: 11px;
    }
  }
}