@import "../../../assets/scss/variables.scss";
 .fileUploadMain {
	width: 100%;
	display: table;
	.fileTitle {
		font-size:14px;
		font-weight: 600;
		line-height: 24px;
		padding-bottom: 8px;
		margin: 0;
		display: block;
		padding: 0;
	}
	.fileUploadInner {
		display: block;
		height: 40px;

		form {
			display: table;
			width: 100%;
			position: relative;
			height: 100%;
			cursor: pointer;
			.textOr {
				font-size:14px;
				font-weight: 600;
				position: absolute;
				top: 34px;
				z-index: 11;
				left: 0;
				left: 47%;
				line-height: 24px;
			}
			.icon-css {
				position: absolute;
				top: 11px;
				left: 10px;
				z-index: 11;
				font-size: 16px;
			}
			.custom-file {
				position: relative;
				display: table;
				width: 100%;
				height: 100%;
				margin-bottom: 0;
				cursor: pointer;
				border: 1px solid $TEXT_RED;
				border-radius: 4px;
				display: flex;
				&:focus {
					outline: none;
				}
				.custom-file-input {
					height: 100%;
					display: block;
					width: 100%;

					&:focus~.custom-file-label {
						border-color: $TEXT_RED;
						box-shadow: none;
						border: none;
					}
				}
				.custom-file-label {
					font-size: 12px;
					font-weight: 600;
					line-height: 24px;
					// padding: 6px 6px 10px 32px;
					height: 100%;
					justify-content: center;
					align-items: center;
					display: flex;
					padding: 0;
					border: none;
					&:after {
						display: none;
					}
					&:focus {
						outline: none;
					}
				}
			}
		}
		.thumbNail {
			position: relative;
			display: table;
			float: left;
			margin-right: 10px;
			.iconCss {
				position: absolute;
				top: 2px;
				right: 2px;
				font-size: 16px;
			}
			.iconBg {
				width: 24px;
				height: 24px;
				background: rgba(0, 0, 0, 0.7);
				border-radius: 50%;
				position: absolute;
				top: 15px;
				left: 45px;
				i {
					display: flex;
					justify-content: center;
					align-self: center;
					padding-top: 5px;
					padding-left: 3px;
					font-size:14px;
					color: white;
				}
			}
		}
	}
	.mainAudio {
		border: 1px solid;
		background: black;
		padding: 8px 8px 8px 8px;
		display: table;
		width: 100%;
		font-weight: 600;
		font-size: 8px;
		// font-family: $primary-font-family;
		.audioTitle {
			color: green;
			font-weight: 600;
			font-size:10px;
			// font-family: $primary-font-family;
		}
		.rhap_container {
			box-shadow: none;
			background: black;
			padding: 0;
			.rhap_controls-section {
				flex: none;
				margin: 0;
				.rhap_main-controls {
					button {
						position: relative;
						
					}
				}
			}
			.rhap_time {
				color:white !important;
			}
			.rhap_progress-bar-show-download {
				background: white;
			}
			.rhap_progress-indicator {
				display: none;
			}
			svg {
				width: 0;
			}
		}
		.rhap_main-controls {
			button {
				width: 40px !important;
			}
		}
	}
}
.dropzoneCustom{
	width:100%;
	padding: 20px 0;
	border: 1px dashedgreen;
	text-align: center;
	border-radius: 4px;
	margin-bottom: 40px;
	cursor: pointer;
		.txtDropzone{
			height: 100%;
			outline: none;
			.joyclub-upload{
				font-size: 24px;
				color:grey;
				width: 100%;
				float: left;
			}
			p{
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				color:grey;
				outline: none;
				margin-bottom: 0px;
			}
			button{
				padding: 0px;
				color: green;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				border: none;
				margin-top: 5px;
				background: transparent;
				outline: none;
			}
		}
}

.fileUploadMain {
	display: flex;
	height: 100%;
	form {
		display: table;
		width: 100%;
		position: relative;
		height: 40px;
		cursor: pointer;
		.icon-css {
			position: absolute;
			top: 11px;
			left: 10px;
			z-index: 11;
			font-size: 16px;
		}
		.custom-file {
			position: relative;
			display: table;
			width: 100%;
			height: 40px;
			margin-bottom: 0;
			cursor: pointer;
			&:focus {
				outline: none;
			}
			.custom-file-input {
				height: 100%;
				display: block;
				width: 100%;
				&:focus~.custom-file-label {
					border-color: $TEXT_RED;
					box-shadow: none;
				}
			}
			.custom-file-label {
				font-size: 12px;
				font-weight: 600;
				padding: 10px 6px 10px 32px;
				height: 40px;
				&:after {
					display: none;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}
	.uploadedSection {
		.thumbNail {
			position: relative;
			display: table;
			float: left;
			margin-right: 10px;
			width: 100%;
			img {
				width: 100%;
				border-radius: 4px;
			}
			.iconCss {
				position: absolute;
				top: 2px;
				right: 2px;
				font-size: 16px;
			}
			.iconBg {
				width: 24px;
				height: 24px;
				background: rgba(0, 0, 0, 0.7);
				border-radius: 50%;
				position: absolute;
				top: 25px;
				left: 60px;
				i {
					display: flex;
					justify-content: center;
					align-self: center;
					padding-top: 5px;
					padding-left: 3px;
					font-size:14px;
					color: white;
				}
			}
		}
	}
}
.errorTxtFile{
	display: flex;
	align-items: flex-start;
	padding: 8px 0 0;
	.joyclub-Info{
		font-size: 20px;
		color:$TEXT_RED;
		margin-right: 6px;
		position: relative;
		top: 2px;
	}
	p{
		font-size: $FONT_14;
		line-height: 24px;
		color:$TEXT_RED;
		font-weight: normal;
	}
}