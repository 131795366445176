@import "../../../assets/scss/variables.scss";
.AppConfigurationsPage {
  padding: 0 15px;
  width: 100%;
  float: left;
  display: inline-block;
  position: relative;
  .contact_details {
    width: 100%;
    float: left;
    padding: 40px 40px 16px;
    .contact_box {
      width: 100%;
      float: left;
      background-color: rgba(50, 14, 59, 0.03);
      padding: 20px 16px;
      .title_row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;
        .title_txt {
          font-size: $FONT_14;
          line-height: 16px;
          font-weight: 400;
          font-family: $SFPROTEXT;
          color: $TEXT_SECONDARY_ONE;
        }
        .icon-pencil {
          padding: 0px;
          margin: 0px;
          font-size: $FONT_14;
          color: $TEXT_SECONDARY_ONE;
          border: none;
          background-color: transparent;
          outline: none;
          font-weight: 600;
        }
      }
      .field_row {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 16px;
        .input_title {
          width: 100%;
          float: left;
          font-size: $FONT_14;
          line-height: 16px;
          color: $TEXT_BLUE_DARK;
          font-weight: 400;
          font-family: $SFPROTEXT;
          padding-bottom: 4px;
        }
        .input_txt {
          width: 100%;
          float: left;
          font-size: $FONT_14;
          line-height: 16px;
          color: $TEXT_SECONDARY_TWO;
          font-weight: 400;
          font-family: $SFPROTEXT;
          word-break: break-word;
          &.outlined {
            border: 1px solid $INPUT_BORDER;
            border-radius: 5px;
            padding: 10px;
            &:last-child {
              margin-top: 10px;
            }
          }
        }
      }
    }
    &.edit_contact_details {
      .title_txt {
        width: 100%;
        float: left;
        padding-bottom: 15px;
        font-size: $FONT_14;
        line-height: 16px;
        font-weight: 400;
        font-family: $SFPROTEXT;
        color: $TEXT_SECONDARY_ONE;
      }
      .input_title {
        width: 100%;
        float: left;
        font-size: $FONT_14;
        line-height: 16px;
        color: $TEXT_SECONDARY_TWO;
        font-weight: 400;
        font-family: $SFPROTEXT;
        padding-bottom: 8px;
      }
      .TextFieldNoRequired {
        border-radius: 8px;
        background-color: #f3f3f5;
      }
      .phone_view {
        width: 100%;
        float: left;
        margin-bottom: 16px;
        background-color: rgba(50, 14, 59, 0.03);
        border-radius: 8px;
        padding: 16px 16px;
        .countryPhoneGroup {
          .icon-phone {
            z-index: 1;
          }
          &::before {
            width: calc(100% + 2px);
            left: -1px;
          }
        }

        .TextFieldNoRequired .form-control {
          padding-right: 16px !important;
        }
      }
      .location_section {
        width: 100%;
        float: left;
        background-color: rgba(50, 14, 59, 0.03);
        border-radius: 8px;
        padding: 16px 16px;
        .TextFieldNoRequired {
          padding: 0px;
          border-radius: 0px;
          background-color: transparent;
        }
        .location_part {
          width: 100%;
          float: left;
          .googleDropdown {
            .css-yk16xz-control {
              border-radius: 8px 8px 0px 0px;
              border-bottom: none;
            }
            .css-1wa3eu0-placeholder {
              &::after {
                display: none;
              }
            }
          }
          .map_view {
            width: 100%;
            float: left;
            padding: 0px 0 0;
            border: 1px solid rgba(50, 14, 59, 0.2);
            border-radius: 0px 0px 8px 8px;
            overflow: hidden;
            .map {
              width: 100%;
              float: left;
            }
          }
        }
      }
      .custombtnfield {
        float: left;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 12px;
        .custombtn {
          min-height: 36px;
          padding: 0px;
          width: 48%;
        }
      }
    }
  }

  .other_details {
    width: 100%;
    float: left;
    padding: 0px 40px 0;
    .other_title {
      font-size: $FONT_16;
      line-height: 20px;
      font-weight: 600;
      font-family: $SFPROTEXT;
      color: $TEXT_SECONDARY_ONE;
      border-top: 1px solid $PLACEHOLDER;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .contact_box {
      width: 100%;
      float: left;
      background-color: rgba(50, 14, 59, 0.03);
      padding: 12px 16px;
      .title_row {
        display: flex;
        justify-content: space-between;
        .title_txt {
          font-size: $FONT_14;
          line-height: 16px;
          font-weight: 400;
          font-family: $SFPROTEXT;
          color: $TEXT_SECONDARY_TWO;
        }
        .icon-pencil {
          padding: 0px;
          margin: 0px;
          font-size: $FONT_14;
          color: $TEXT_SECONDARY_ONE;
          border: none;
          background-color: transparent;
          outline: none;
          font-weight: 600;
        }
      }
    }
  }
}
