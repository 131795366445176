// Background Colors
$BG_Body:#F3F3F6;
$BG_BLACK: #000000;
$BG_BLACK_DARK: #252525;
$BG_GREY:#585858;
$BG_GRET_LIGHT:#E5E5E6;
$BG_BLUE_LIGHT:#9EA4B9;
$BG_GREY_LIGHT:#ECECEC;
$BG_WHITE:#ffffff;
$BG_YELLOW: #FEC242;
$BG_SECONDARY_ONE:#320E3B;
$BG_SECONDARY_TWO:#3A4468;
$BG_BLUE_DARK:#838AA3;
$BG_RED: #FF4539;
$BG_GREEN: #32D74C;
$BG_GREEN_LIGHT:#79CA5B;
$BG_GREEN_DARK:#34C759;
$BG_YELLOW_LIGHT: #FFD60A;
$BG_DISABLE:#DDDDDD;
$BG_OPACITY:#F2EEF7;
$BG_BOX:rgba(50, 14, 59, 0.03);

// Text Colors
$TEXT_BLACK: #000000;
$TEXT_BLACK_DARK: #252525;
$TEXT_GREY:#585858;
$TEXT_BLUE_LIGHT:#9EA4B9;
$TEXT_GREY_LIGHT:#ECECEC;
$TEXT_WHITE:#ffffff;
$TEXT_YELLOW: #FEC242;
$TEXT_SECONDARY_ONE:#320E3B;
$TEXT_SECONDARY_TWO:#3A4468;
$TEXT_BLUE_DARK:#838AA3;
$TEXT_RED: #FF4539;
$TEXT_GREEN: #32D74C;
$TEXT_GREEN_LIGHT:#79CA5B;
$TEXT_GREEN_DARK:#34C759;
$TEXT_YELLOW_LIGHT: #FFD60A;
$PLACEHOLDER: #BBBBBB;
$TEXT_BLUE_DARK_LIGHT:#1F2F47;

// border-color
$INPUT_BORDER:rgba(50, 14, 59, 0.2);
$LINE_BORDER:rgba(50, 14, 59, 0.3);
$LINE_BORDER_BOTTOM:rgba(131, 138, 163, 0.3);
// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_15: 15px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_30: 30px;
$FONT_36: 36px;
$FONT_48: 48px;
$FONT_60: 60px;
$FONT_96: 96px;


// Font Family
$SFPROTEXT: 'SF Pro Text';
